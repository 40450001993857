<template>
  <div v-if="lesson" class="pt-3">
    <page-breadcrumb :title="`Pronun Lesson Detail`" :items="breadcrumbItems" />

    <section class="my-3">
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="lesson.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <strong class="h6">Title</strong>
            <b-form-input
              class="mb-1"
              type="text"
              v-model="lesson.title"
              placeholder="Enter your title here"
            />
            <strong class="h6">Tag IS</strong>
            <b-form-input
              class="mb-1"
              type="number"
              v-model="lesson.completed_tag"
            />
            <strong class="h6">Completed Tag ID</strong>
            <ui-component
              :component-data="lesson.completed_tag_id"
              class="border"
            />
            <strong class="h6">Free / Paid ?</strong>
            <div class="d-flex">
              <b-form-checkbox
                class="custom-control-primary"
                name="is_free_checkbox"
                switch
                v-model="lesson.is_free"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="HeartIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="DollarSignIcon" />
                </span>
              </b-form-checkbox>
              <strong>{{ lesson.is_free ? "Free" : "Paid" }}</strong>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <section class="my-3" v-if="steps">
      <step-card
        v-for="step in lesson.steps"
        :key="step.key"
        :step="step"
        :topics="[]"
      >
        <div class="mt-1" v-if="steps[step.key]">
          <topic-card
            v-for="topic in steps[step.key].topics"
            :key="topic._id"
            :topic="topic"
          />
        </div>
        <div class="d-flex justify-content-center">
          <topic-create-form
            :lesson_id="lesson._id"
            :step_key="step.key"
            :display_order="
              steps[step.key] ? steps[step.key].latest_display_order : -1
            "
          />
        </div>
      </step-card>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteLesson"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import topicService from "../../pronun_topic/service";
import StepCard from "./_components/StepCard.vue";
import TopicCreateForm from "./_components/TopicCreateForm.vue";
import TopicCard from "./_components/TopicCard.vue";

export default {
  components: {
    StepCard,
    TopicCreateForm,
    TopicCard,
  },
  data() {
    return {
      lesson: null,
      isUpdating: false,
      isDeleting: false,
      topics: [],
      steps: null,
    };
  },
  computed: {
    lessonId() {
      return this.$route.params.lesson_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Pronun Lesson List",
          to: { name: "pronun-lesson-list" },
        },
      ];
      items.push({ text: "Pronun Lesson Detail", active: true });
      return items;
    },
  },
  async created() {
    await this.getLesson();
    this.getTopics();
  },
  methods: {
    async getLesson() {
      this.lesson = await service.get({ id: this.lessonId });
    },
    async update() {
      this.isUpdating = true;
      const response = await service.update({
        data: JSON.stringify(this.lesson),
      });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully updated lesson!",
        });
      }
      this.getLesson();
      this.isUpdating = false;
    },
    async deleteLesson() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.lessonId });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully deleted lesson!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "pronun-lesson-list" });
    },
    async getTopics() {
      let result = await topicService.getAll({
        page: 1,
        limit: 900,
        query: encodeURIComponent(JSON.stringify({ lesson_id: this.lessonId })),
        order_by: "display_order",
        order: 1,
      });
      this.topics = result.list;
      let steps = {};
      let display_order = -1;
      this.lesson.steps.forEach((step) => {
        let step_topics = this.filterTopics(step.key);
        let latest_display_order = step_topics.length
          ? step_topics[step_topics.length - 1].display_order
          : display_order;
        steps[step.key] = {
          name: step.name,
          key: step.key,
          topics: step_topics,
          latest_display_order,
        };
        display_order = latest_display_order;
      });
      this.steps = steps;
    },
    filterTopics(step_key) {
      return this.topics.filter((item) => {
        return item.step_key === step_key;
      });
    },
  },
};
</script>

<style scoped>
.page-item {
  transition: ease 0.6s;
}
</style>
