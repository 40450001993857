<template>
  <b-button variant="outline-primary" pill @click="create">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else
      icon="PlusIcon"
      class="mr-50"
    />
    <span class="align-middle">Add Topic</span>
  </b-button>
</template>

<script>
import service from '../../../pronun_topic/service'

export default {
  props: {
    lesson_id: { type: String, required: true },
    step_key: { type: String, required: true },
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async create() {
      this.isLoading = true
      let new_item = await service.create({ lesson_id: this.lesson_id, step_key: this.step_key, display_order: this.display_order })
      this.isLoading = false
      if (new_item) {
        this.$router.push({ name: 'pronun-topic-detail', params: { topic_id: new_item._id } })
      }
    },
  },
}
</script>
