<template>
  <b-link
    :to="{ name: 'pronun-topic-detail', params: { topic_id: topic._id } }"
  >
    <b-card border-variant="secondary">
      <b-media>
        <template #aside>
          <b-img
            :src="require('@/assets/images/icons/microphone.png')"
            blank-color="#ccc"
            width="64"
            alt="placeholder"
          />
        </template>
        <h4 class="media-heading">
          <render-translatable-text :component-data="topic.title_translate" />
        </h4>
        <small class="d-block mt-2" style="color: #bbb">
          Last edited: {{ topic.updatedAt | local_time_string }}
        </small>
      </b-media>
    </b-card>
  </b-link>
</template>

<script>
export default {
  props: {
    topic: { type: Object, required: true },
  },
};
</script>
